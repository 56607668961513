@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  position: relative;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: auto;
}


.message-box-text {
  font-size: 0.9rem;
}


.error {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2.5rem;
}

.parent {
  background-color: #f5fbf7;
}


.ruler {
  width: 100%;
  height: 1px;
  background-color: #c8bfbf;
}


.hero {
  max-width: 100%;
  height: auto;
  max-height: 500px;
}

.nike {
  max-width: 100%;
  height: auto;
}

.whatsapp {
  max-width: 5rem;
  height: auto;
  position:fixed;
  bottom: 3rem;
  right: 0;
  width:100%;
  z-index:10000;
}

.socials {
  width:100%;
  max-width: 3rem;
  height: auto;

}

.socials-instagram {
  width:100%;
  max-width: 2.7rem;
  height: auto;

}

.nav {
  display: flex;
  justify-content: space-between;
}

.btn-primary .btn-outline-primary {
  background-color: #f0c040;
  color: #000;
}


.img-large {
  max-width: 100%;
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.product-rating {
  width: 120px;
}

.product-rating span {
  color: #ffaf0e;
}

.product-rating .caption {
  color: #000;
}

.entry-rating span {
  color: #ffaf0e;
}

.product-rating .star-reviews {
  color: #000;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
  font-size: 0.75rem;
}

.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f06800;
  color: #f06800;
  font-size: 0.75rem;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}

.side-navbar {
  width: 200px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #212524;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}

.active-cont {
    margin-left: 200px
}

.active-nav {
  left: 0;
}

button.thumbnail {
  padding: 0;
}

.brand-hidden {
  display: none;
}

.brand-show {
  display: block;
}

.brands {
  align-items: center;
}

.brand-view {
  max-width: 300px;
  max-height: 400px;
}

@media screen and (max-width: 600px) {
  table thead {
    display: none;
  }

  table td {
    display: flex;
  }
  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 10px;
    min-width: 10px;
  }
}


/* autoscroll */

.slide {
  /* width: 100%; */
  /* height: 100%; */
  /* opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease; */
}


