.message-box-text {
    font-size: 0.9rem;
    width: 100%;
}

.create-product-page {
    font-family: 'Inter', sans-serif;
    color: #1a1a1a;
    font-size: .9rem;
}

.create-product-page .create-label {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 0.95rem;
    font-weight: 600;
}

.create-product-page .input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}

.create-product-page .create-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    padding: 0.8rem 0;
    margin-top: .7rem;
}

.create-product-page textarea {
    width: 100%;
    height: 150px;
    border: 2px #1a1a1a3b solid;
    border-radius: 0.5rem;
    padding: 0.8rem;
}

@media screen and (min-width: 600px) {
    .create-product-page .create-button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        padding: 0.8rem 0;
        margin-top: .7rem;
        width: 170px;
    }
  }

.size-check-box {
    outline: none;
    width: 1.7rem;
    height: 1.7rem;
    display: block;
}
