
.userlist-page {
    font-family: 'Inter', sans-serif;
    color: #1a1a1a;
    font-size: .9rem;
}

.userlist-page .mobile-header {
    font-size: .9rem;
}

.no-users {
    font-size: 0.9rem;
}