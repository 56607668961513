.web-test {
    background-color: #00162B;
    width: 40%;
}


.form {
    padding: 2rem 1.3rem;
    border-radius: 1rem;
    background-color: #f4f4f5;
}

.form .main-form {
    margin-top: 1.7rem;
}

.form h1 {
    color: #1a1a1a;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
}

.form p {
    color: #1a1a1a7c;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
}

.form .label {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
}

.form input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}


.form .button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
}


.back-arrow {
    position: absolute;
    border: 2px solid black;
    top: 0.2rem;
    border-radius: 50%;
}

.back {
    margin-left: 1.7rem;
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
}
