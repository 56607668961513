
.profile-page h1 {
    color: #1a1a1a;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    margin-bottom: .2rem;;
}

.profile-page input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}

.profile-page p {
    color: #1a1a1a7c;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 1.4rem;;
}

.profile-page .label {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 0.95rem;
    font-weight: 600;
}

.profile-page .profile-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    padding: 0.8rem 0;
   width: 170px;
}

.profile-page .profile-cancel-button {
    background-color: #e4e4e7;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    padding: 0.8rem 0;
    width: 170px;
    color: black;
    font-weight: 500;
}


@media screen and (min-width: 600px) {
    .profile-page .profile-button-group {
        width: 360px;
    }
}