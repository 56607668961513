
@media screen and (min-width: 600px) {
    .best-seller-product {
        border: 1px rgba(173, 173, 173, 0.709) solid;
        font-family: 'Inter', sans-serif;
        max-height: 40rem;
        display: block;
        width: 300px;
        height: 475px;
        padding: 1rem;
        border-radius: .4rem;
    }

    .best-seller-product .image {
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 300px;
    }

    .best-seller-product .button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 0.9rem;
        color: white;
        padding: 0.8rem;
        border-radius: .5rem;
        margin-top: 0.9rem;
    }
}

@media screen and (max-width: 600px) {
    .best-seller-product {
        border: 1px rgba(173, 173, 173, 0.709) solid;
        font-family: 'Inter', sans-serif;
        max-height: 40rem;
        display: block;
        width: 100%;
        height: 350px;
        padding: .4rem;
        border-radius: .4rem;
    }

    .best-seller-product .image {
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 200px;
    }
    
    .best-seller-product .button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        color: white;
        padding: 0.8rem;
        border-radius: .4rem;
        margin-top: 0.9rem;
    }
}


.best-seller-product .content {
    border-bottom: 1px rgba(173, 173, 173, 0.709) solid;
    padding: 0.75rem 0;
}

.best-seller-product .price {
    font-weight: 500;
    color: black;
    font-size: .8rem;
}

.best-seller-product .name {
    font-weight: 500;
    color: #1a1a1a82;
    font-size: .8rem;
    margin-top: -.6rem;
  }

.best-seller-product .brand {
    position: absolute;
    top: 1rem;
    left: 1.1rem;
    font-family: 'Inter', sans-serif;
    font-size: 0.7rem;
    padding: .1rem 1.1rem;
    border-radius: .3rem;
    border: 1px rgba(173, 173, 173, 0.709) solid;
}

