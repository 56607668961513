.order-page {
    text-align: center;
}

.order-page .input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}

.order-page .input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}

.order-page .label {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: left;
    margin-bottom: .8rem;
}

.order-page .order-h1 {
    color: #1a1a1a;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.3rem;
    margin-bottom: 2rem;
}

.order-page .order-p {
    color: rgba(0, 0, 0, 0.512);
    font-family: 'Inter', sans-serif;
    font-size: .9rem;
    margin-bottom: .9rem;
    line-height: 1.7rem;
}

.order-id {
    font-size: 1rem;
    color: hsl(0, 0%, 10%);
    width: 260px;
    margin-top: 1.9rem;
}

.order-border {
    width: 135px;
    background-color: #e4e4e7;
    color: #ef4343;
    border-radius: .2rem;
}

.order-id-text {
    color: #1a1a1ad0;
}


@media screen and (min-width: 600px) {
    .order-button .pay-button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        margin-top: .8rem;
        padding: 1rem 3rem;
        width: 100%;
    }
  
}

@media screen and (max-width: 600px) {
    .order-button .pay-button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        margin-top: .8rem;
        padding: 1rem 3rem;
        width: 100%;
    }
  
}


/* /* .pal-buttons {
    margin-top: 1rem;
} */

.paypal-group h2  {
    text-align: center;
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    margin: .5rem 0;
    font-weight: 700;
    margin-bottom: 2rem;
}


/* .paypal-group span {
    color: #1a1a1a8c;
    line-height: 0.7rem;
    margin-bottom: 2rem;
}  */