@media screen and (min-width: 600px) {
    .web-gallery-bg {
      background-image: url("./web-gallery-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 500px;
    }
     
    .gallery-image {
      width: 100%;
      max-width: 410px;
      height: 100%;
      max-height: 400px;
      margin-bottom: 1rem;
  }

  .gallery-page h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #000;
    font-size: 2rem;
    margin: 4rem auto 0.7rem;
    width: 15rem;
    line-height: 3rem;
  }

  .gallery-page p {
    font-family: 'Inter', sans-serif;
    line-height: 1.34rem;
    color: #1a1a1ab1;
    font-size: 0.9rem;
    margin: 0 auto 1.7rem;
    width: 30rem;
  }

  .gallery-page .gallery-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: white;
    padding: 0.8rem;
    border-radius: .5rem;
    margin:1.8rem auto 0; 
    width: 250px;
}

  .gallery-page h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #000;
    font-size: 2rem;
    margin: 4.3rem auto 1rem;
  }

  .gallery-page .happy-customers p {
    font-family: 'Inter', sans-serif;
    line-height: 1.34rem;
    color: #1a1a1ab1;
    font-size: 0.9rem;
    margin: 0 auto 1.7rem;
  }

  .happy-customers {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {

    .gallery-image {
        width: 100%;
        max-width: 410px;
        height: 100%;
        max-height: 400px;
        margin-bottom: 1rem;
    }

  .mobile-gallery-bg {
        background-image: url("./mobile-gallery-bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 350px;
      }

      

      .gallery-page h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        color: #000;
        font-size: 2rem;
        margin: 4rem auto 1.4rem;
        width: 15rem;
        line-height: 3rem;
      }

      .gallery-page p {
        font-family: 'Inter', sans-serif;
        line-height: 1.34rem;
        color: #1a1a1ab1;
        font-size: 0.9rem;
        margin: 0 auto 2.4rem;
      }

      .gallery-page {
        text-align: center;
        width: 90%;
        margin: 1.5rem 0;
      }

      .gallery-page .gallery-button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 0.9rem;
        color: white;
        padding: 1rem;
        border-radius: .5rem;
        margin-top: 0.9rem;
    }

    .gallery-page h2 {
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      color: #000;
      font-size: 2rem;
      margin: 3.6rem auto 1rem;
      line-height: 3rem;
    }

    .gallery-page .happy-customers p {
      font-family: 'Inter', sans-serif;
      line-height: 1.34rem;
      color: #1a1a1ab1;
      font-size: 0.9rem;
      margin: 0 auto 2.4rem;
      width: 15rem;
    }
    
    
}
