
.orderlist-page {
    font-family: 'Inter', sans-serif;
    color: #1a1a1a;
    font-size: .9rem;
}

.orderlist-page .mobile-header {
    font-size: .9rem;
}

.no-order {
    font-size: 0.9rem;
}