

.refund h1 {
    color: #1a1a1a;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.7rem;
}


.refund ul li {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    margin-top: 0.3rem;
    line-height: 1.7rem;
}

/* large screen */
@media screen and (min-width: 600px) {
    .refund p {
        color: #1a1a1a;
        font-family: 'Inter', sans-serif;
        line-height: 1.7rem;
        text-align: justify;
        font-size: 1rem;
    }
    .refund ul li {
        color: #1a1a1a;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        margin-top: 0.3rem;
    }
}

/* small screen */
@media screen and (max-width: 600px) {
    .refund p {
        color: #1a1a1a;
        font-family: 'Inter', sans-serif;
        line-height: 1.7rem;
        text-align: justify;
        font-size: 0.8rem;
    }
    .refund ul li {
        color: #1a1a1a;
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        margin-top: 0.3rem;
    }
    
}
