@media screen and (min-width: 600px) {

    .sales-slider .sales-slide {
        transform: translateX(0%);
        transition: all 0.5s ease-in-out;
        text-align: center;
        color: #1a1a1a;
        margin-top: .8rem;
        font-family: 'Inter', sans-serif;
    }

    .sales-slider {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 54px;
        background-color: #f4f4f5;
    }

    .sales-slider .sales-slide .link {
        font-size: 1rem;
        text-decoration: underline;
    }
    
    .sales-slider .sales-slide p {
        font-size: 1.2rem;
        margin-bottom: .3rem;
        font-weight: bold;
    }

    .sales-slide span {
        font-size: 1rem;
        font-weight: bold;
    }
}

@media screen and (max-width: 600px) {
    .sales-slider .sales-slide {
        transform: translateX(0%);
        transition: all 0.5s ease-in-out;
        text-align: center;
        color: #1a1a1a;
        font-size: 0.8rem;
        margin-top: .5rem;
        font-family: 'Inter', sans-serif;

    }

    .sales-slider {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 50px;
        background-color: #f4f4f5;
        padding: 0 1rem;
    }

    .sales-slider p {
        font-family: 'Inter', sans-serif;
        font-size: .8rem;
    }

    .sales-slider .sales-slide .link {
        text-decoration: underline;
        font-size: .8rem;
    }
    
    .sales-slider .sales-slide p {
        margin-bottom: .3rem;
        font-weight: bold;
    }

    .sales-slider .sales-slide span {
        font-size: .8rem;
        font-weight: bold;
    }
}

.sales-slider .sales-current {
    opacity: 1;
    transform: translateX(100%);
}
