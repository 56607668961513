
.new-arrival-comp .slider-button {
    background-color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-size: 0.7rem;
    color: rgb(0, 0, 0);
    border: 1px #003466 solid;
    width: 6.8rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
}

.new-arrival-comp .new-arrivals {
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.new-arrival-comp .wrapper {
    width: 72%;
    margin: 0 auto;
}

@media screen and (min-width: 600px) {
    .new-arrival-comp .new-arrivals {
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        color: rgb(0, 0, 0);
        font-weight: 600;
    }

    .slider{
        width: 100%;
    }

    .new-arrival-comp .slider-button {
        background-color: #ffffff;
        font-family: 'Inter', sans-serif;
        font-size: 0.7rem;
        color: rgb(0, 0, 0);
        border: 1px #003466 solid;
        width: 6.8rem;
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        margin-right: .8rem;
    }
}

@media screen and (max-width: 600px) {
    .new-arrival-comp .new-arrivals {
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        color: rgb(0, 0, 0);
        font-weight: 600;
    }
    
  
}


/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;


    position: absolute;
    top: 15rem !important;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 10px;
    line-height: 1;

    opacity: .75;
    color: black !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}



/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 20px !important;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;

}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


.new-arrival-comp .new-arrival-slide {
    opacity: 0;
    transform: translateX(50%);
    transition: all 0.5s ease; 
}

.new-arrival-comp .new-arrival-arrow {
    border: none;
    background-color: transparent;
    color: #1a1a1a;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
    top: 34.7rem;
    left: 90%;
}

.new-arrival-comp .new-arrival-arrow:hover {
    color: rgb(0, 0, 0);
}

.new-arrival-comp .new-arrival-next {
    margin-right: 50px;
    
}

.new-arrival-comp .new-arrival-current {
    opacity: 1;
    transform: translateX(0);
}

.new-arrival-comp .new-arrival-prev {
    margin-left: 50px;
}

.new-arrival-comp .new-arrival-content {
    animation-fill-mode: forwards;
}


.new-arrival-comp span {
    font-size: 0.8rem;
    color: #1a1a1ab9;
}
