.gallery-comp .mobile-gallery-image {
    background-image: url("./mobile-gallery.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
  }

  /* .gallery-comp .mobile-gallery {
    height: 100%;
    width: 100%;
  } */

.gallery-comp .title {
    margin-bottom: 3rem;
    text-align: center;
}

.gallery-comp .title h2 {
    font-size: 1.4rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    color: #000000;
    margin-bottom: 1rem;
}

.gallery-comp .title p {
    font-size: .9rem;
    line-height: 1.4rem;
    color: #000000;
    font-family: 'Inter', sans-serif;
}


.gallery-comp .shop-now-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #ffffff;
    width: 200px;
    cursor: pointer;
    padding: 0.8rem;
    border-radius: 0.5rem;
}

.gallery-comp .explore-button {
    background-color: #f4f4f5;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #1a1a1a;
    width: 200px;
    cursor: pointer;
    padding: 0.8rem;
    border: 1px #003466 solid;
    border-radius: 0.5rem;
    margin: 0;
}

@media screen and (min-width: 600px) {
  .gallery-comp .gallery-buttons {
    width: 400px;
  }

  .web-gallery-slider .web-gallery-slide {
      transform: translateX(0%);
      transition: all 0.5s ease-in-out;
      text-align: center;
      color: #1a1a1a;
  }

  .web-gallery-slider {
      width: 95%;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
  }

  /* .web-gallery-slider .web-gallery-current {
      opacity: 1;
      transform: translateX(100%);
  } */

   .web-gallery-image {
    height: 600px;
    width: 100%;
  }

  .gallery-comp .title h2 {
    font-size: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    color: #000000;
    margin-bottom: 1rem;
}

.gallery-comp .title p {
    font-size: 1rem;
    line-height: 1.4rem;
    color: #000000;
    font-family: 'Inter', sans-serif;
}
}

@media screen and (max-width: 600px) {
  .mobile-slider .mobile-slide {
    transform: translateX(0%);
    transition: all 0.5s ease-in-out;
    text-align: center;
    color: #1a1a1a;
  }

  .mobile-slider {
      width: 90%;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
  }

  /* .mobile-slider .mobile-current {
      opacity: 1;
      transform: translateX(100%);
  } */

  .mobile-image {
      height: 700px;
      width: 100%;
  }
}