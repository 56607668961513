.shipping-contact {
    color: #1a1a1ab5;
    font-size: 0.9rem;
}

.shipping-form {
    padding: 1.5rem;
    border-radius: 0.5rem;
}

.shipping-form .input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}


.h1 {
    color: #000000eb;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
}

.shipping-contact h2 {
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    margin-top: 2rem;
}

.shipping-contact .payment {
    border-top: 1px #1a1a1a3b solid;
    margin-top: 2rem;
}


.shipping-contact .button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    margin-top: .8rem;
}

.shipping-contact textarea {
    width: 100%;
    height: 150px;
    border: 2px #1a1a1a3b solid;
    border-radius: 0.5rem;
    padding: 0.8rem;
}

@media screen and (min-width: 600px) {
    .billing-address {
        width: 310px;
    }

    .shipping-contact .biling-same {
        color: #1a1a1ac5;
        font-family: 'Inter', sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
        /* width: 2000px; */
    }
}

@media screen and (max-width: 600px) {

    .billing-address {
        width: 280px;
    }

    .shipping-contact .biling-same {
        color: #1a1a1ac5;
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        /* width: 1700px; */
        margin-left: -1.5rem;
    }
}

.cart-item .cart-image-group {
    width: 25%; 
    height: 100%;
}

.box {
    outline: none;
}

.shipping-item {
    border-bottom: 1px #1a1a1a34 solid;
    padding: 1rem 0;
}

.shipping-item .shipping-image-group {
    width: 25%; 
    height: 100%;
}

.shipping-item .shipping-image {
    width: 100%;
    height: 30%;
}

.shipping-item .shipping-item-content {
    width: 70%;
}

.shipping-form {
    border: 1px #1a1a1a3b solid;
    padding: 1.5rem;
    border-radius: 0.5rem;
}

.order-details {
    padding: 1.5rem;
    border-radius: 0.5rem;
}

.order-details ul {
    border-top: 1px #1a1a1a34 solid;
    padding-top: 1.5rem;
}

.order-details .order-total {
    border-top: 1px #1a1a1a34 solid;
    padding-top: 1.5rem;
}

.order-details ul li {
    margin-bottom: .8rem;
}

.order-details p {
    color: #1a1a1aaf;
    font-size: .9rem;
}

.order-details span {
    color: #1a1a1a;
    font-size: .9rem;
    font-weight: 600;
}

.order-details .shipping-class {
    color: #c4c4c4c2;
    font-size: .9rem;
    font-weight: 600;
}

.shipping-item-images {
    padding: 1.5rem;
    border-radius: 0.5rem;
}

.s-item-name-price {
    color: #1a1a1ae2;
    font-size: .9rem;
    font-weight: 600;
}

.s-item-name {
    font-size: 0.8rem;
    color: #1a1a1a9c;
}

.shipping-item:last-of-type {
    border: none;
}