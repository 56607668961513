.mobile-error {
    margin-top: 2.5rem;
}

.mobile-error .mobile-error-text h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #000;
    font-size: 1.7rem;
    margin: 1.6rem auto 0.7rem;
}

.mobile-error .mobile-error-text p {
    font-family: 'Inter', sans-serif;
    line-height: 1.34rem;
    color: #1a1a1ab1;
    font-size: 0.9rem;
}

.mobile-error .mobile-error-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: white;
    padding: 0.8rem;
    border-radius: .5rem;
    margin:1.8rem auto 0; 
    width: 250px;
}




.web-error {
    margin-top: 2.5rem;
}

.web-error .web-error-text h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #000;
    font-size: 2.7rem;
    margin-bottom: 1.3rem;
}

.web-error .web-error-text p {
    font-family: 'Inter', sans-serif;
    line-height: 1.34rem;
    color: #1a1a1ab1;
    font-size: 1.2rem;
}

.web-error .web-error-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: white;
    padding: 0.8rem;
    border-radius: .5rem;
    margin-top: 1.8rem; 
    width: 250px;
}

.web-error .web-error-image {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 600px;
}


