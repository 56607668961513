
@media screen and (min-width: 600px) {
  .product {
    font-family: 'Inter', sans-serif;
    max-height: 40rem;
    display: block;
    width: 100%;
    height: 600px;
    padding-right: 1rem;
  }

  .product .image {
    width: 100%;
    /* max-width: 400px; */
    height: 100%;
    max-height: 450px;
  }
}

@media screen and (max-width: 600px) {
  .product {
    font-family: 'Inter', sans-serif;
    display: block;
    width: 100%;
    height: 300px;
    margin-top: 1rem;
  }

  .product .image {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 200px;
  }
}

  .product .product-name {
    font-weight: 500;
    color: black;
    font-size: .9rem;
  }

  .product .brand {
    font-weight: 200;
    font-size: 0.8rem;
    color: rgb(97, 97, 97);
  }
  
  .product .price {
    font-weight: 500;
    color: black;
    font-size: .9rem;
  }

 


  
 
  
