
.search-page {
        width: 92%;
        position: relative;
        font-size: 0.9rem;
}

.search-page-body {
    width: 100%;
    margin: 0 auto;
    position: relative;
    transition: all 1s ease-in-out;
}

.search-page-body-filter {
    transition: all 2s ease-in-out;
    opacity: 0.2;
    width: 100%;
    margin: 0 auto;
    position: relative;;
}


.sort-box {
    width: 160px;
}

.sort option {
    font-size: 0.9rem;
}

.filter-group {
    font-family: 'Inter', sans-serif;
    display: block;
    height: 200vh;
    position: absolute;
    top: 240px;
    z-index: 10000;
    background-color: #ffffff;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.filter-hidden {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    width: 100%;
    background-color: #ffffff;
    height: 200vh;
    position: absolute;
    top: 240px;
    z-index: 10000;
    margin-left: -100%;
    transition: all 0.5s ease-in-out;
}

.filter-content {
    padding: .8rem 1.5rem 0;
    font-size: 0.9rem;
}

.filter-content h1 {
    color: #1a1a1a;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
}

.filter-content h3 {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    margin: 1rem 0 0.5rem;
    font-size: 1rem;
    font-weight: 600;
}

.filter-content li {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    padding-bottom: 0.4rem;
    font-size: .9rem;
}

.upCategory {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
    margin-top: 0.8rem;
}

.downCategory {
    display: none;  
    cursor: pointer;
}

.upBrand {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
    margin-top: 0.8rem;
}

.downBrand {
    display: none;  
    cursor: pointer;
}

.upPrice {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
    margin-top: 0.8rem;
}

.downPrice {
    display: none;  
    cursor: pointer;
}

.upRating {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
    margin-top: 0.8rem;
}

.downRating {
    display: none;  
    cursor: pointer;
}

.filter-drop-down {
    width: 132px;
}

.upFilter {
    display: block;
    width: 1.8rem;
    height: 1.5rem;
    cursor: pointer;
    margin-top: 0.2rem;
}

.downFilter {
    display: none;  
    cursor: pointer;
}

.all-products {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
}

.nav-active {
    background-color: #1a1a1a;
    color: white;
    font-size: .8rem;
    padding: .5rem .9rem;
    border-radius: .4rem;
}

.nav-inactive {
    background-color: white;
    color: #1a1a1a;
    font-size: .8rem;
    border: 1px red #1a1a1a;
    padding: .5rem .9rem;
    border-radius: .4rem;
}