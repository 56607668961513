.best-sellers-comp .best-sellers-button {
    background-color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-size: 0.7rem;
    color: rgb(0, 0, 0);
    border: 1px #003466 solid;
    width: 6.8rem;
    padding: 0.5rem .8rem;
    border-radius: 0.3rem;
}


@media screen and (min-width: 600px) {
   .best-sellers-comp .box {
        width: 72%;
    }

    .best-sellers-comp .best-sellers {
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        color: rgb(0, 0, 0);
        font-weight: 600;
    }

    .best-sellers-comp .best-sellers .best-arrival-product {
        margin-bottom: 8.3rem;
        border: 1px white solid;
    }

    .best-sellers-comp .best-sellers-button {
        background-color: #ffffff;
        font-family: 'Inter', sans-serif;
        font-size: 0.7rem;
        color: rgb(0, 0, 0);
        border: 1px #003466 solid;
        width: 6.8rem;
        padding: 0.5rem .8rem;
        border-radius: 0.3rem;
        margin-right: .8rem;
    }
}

@media screen and (max-width: 600px) {
    .best-sellers-comp .box {
        width: 92%;
    }

    .best-sellers-comp .best-sellers {
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        color: rgb(0, 0, 0);
        font-weight: 600;
    }
}
