@font-face {
    font-family: 'CabinetGrotesk';
    src: url(./Fonts/CabinetGrotesk-Bold.ttf);
  }
  

.bg-image-comp .mobile-bg {
    font-family: 'Inter', sans-serif;
    height: 100%;
    width: 100%;
  }

.bg-image-comp .mobile-bg-image {
    background-image: linear-gradient(rgba(3, 3, 3, 0.529), rgba(13, 13, 16, 0.6)), url("./mobile-bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    height: 400px;
  }

.bg-image-comp .mobile-bg-content {
    width: 92%;
    font-family: 'Inter', sans-serif;
    padding-top: 4rem;
}

.bg-image-comp .mobile-bg-content h1{
    font-size: 1.8rem;
    line-height: 2.1rem;
    font-family: 'CabinetGrotesk', sans-serif;
    font-weight: 500;
    color: #ffffff;
    width: 70%;
    margin-bottom: 2rem;
}

.bg-image-comp .mobile-bg-content p {
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: #ffffffcf;
    width: 70%;
    margin-bottom: 2rem;
}

.bg-image-comp .mobile-bg-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    color: #ffffff;
    width: 30%;
    cursor: pointer;
}

.bg-image-comp .web-bg {
    font-family: 'Inter', sans-serif;
    height: 100%;
    width: 100%;
  }

.bg-image-comp .web-bg-image {
    background-image: linear-gradient(rgba(3, 3, 3, 0.529), rgba(13, 13, 16, 0.6)), url("./web-bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    height: 600px;
}

.bg-image-comp .web-bg-content {
    width: 88%;
    font-family: 'Inter', sans-serif;
    padding-top: 10rem;
}

.bg-image-comp .web-bg-content h1{
    font-size: 3rem;
    line-height: 3.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    color: #ffffff;
    width: 60%;
    margin-bottom: 1.5rem;
}

.bg-image-comp .web-bg-content p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #ffffffcf;
    width: 55%;
    margin-bottom: 2rem;
}

.bg-image-comp .web-bg-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    color: #ffffff;
    width: 10rem;
    cursor: pointer;
}