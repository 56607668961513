.header {
    width: 100%;
    background-color: #fff;
    /* position: relative; */
}

.top-nav {
    color: #ffffff;
    background-color: #131313;
    font-family: 'Inter', sans-serif;
}

.socials {
    max-width: 8rem;
}

.gallery-refund {
    font-size: 0.9rem;
}

.search-box input::placeholder {
    color: #1a1a1a82;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    padding-left: 0.1rem;
}

.search-profile-cart {
    width: 23rem;
}

.search-cart-ham {
    width: 7.5rem;
}

.dropdown-toggle::after {
    display: none !important; 
}

.signin {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
}

.signin:hover {
    font-weight: 500;
    width: auto;
}

.web-second {
    border-bottom: 1px #4a4a4a28 solid;
    height: 70px;
    padding: 1rem 1.2rem;
}

.mobile-second {
    padding: 0.2rem 1.2rem;
    border-bottom: 1px #4a4a4a28 solid;
}

.page-navs {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
}

.brand-header .brand{
    width: auto;
}

.brand-header li:hover {
    font-weight: 500;
    width: auto;
}

.brand-header {
    width: 55rem;
}

.brands {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mobile-brands {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.side-body {
    border-top: 1px #4a4a4a28 solid;
}

.mobile-side-nav {
        font-family: 'Inter', sans-serif;
        padding: .2rem 2rem;
        display: block;
        height: 300vh;
        position: absolute;
        z-index: 50000;
        background-color: #ffffff;
        width: 100%;
        transition: all 0.5s ease-in-out;
}
    
.hide-mobile-side-nav {
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        width: 100%;
        padding: .2rem 2rem;
        background-color: #ffffff;
        height: 300vh;
        position: absolute;
        z-index: 50000;
        margin-left: -100%;
        transition: all 0.5s ease-in-out;
 }

 .mobile-side-nav .button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    border: 1px #003466 solid;
 }

 .mobile-side-nav .sign-in-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    border: 1px #003466 solid;
    padding: .8rem 0;
 }



 .mobile-side-nav .signup .button{
    background-color: #f4f4f5;
    border: 1px #003466 solid;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #003466;
    margin-top: 1rem;
 }

 .mobile-side-nav .signout .button{
    background-color: #f4f4f5;
    border: 1px #003466 solid;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #003466;
    margin-top: 1rem;
 }


.mobile-side-nav .content li {
    margin-top: 1rem;
    font-size: .9rem;
    width: 100%;
}


.hide-mobile-side-nav .button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    border: 1px #003466 solid;
 }

 .hide-mobile-side-nav .signup .button{
    background-color: #f4f4f5;
    border: 1px #003466 solid;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #003466;
    /* font-weight: bold; */
    margin-top: 1rem;
 }

 .hide-mobile-side-nav .signout .button{
    background-color: #f4f4f5;
    border: 1px #003466 solid;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #003466;
    /* font-weight: bold; */
    margin-top: 1rem;
 }


.hide-mobile-side-nav ul li {
    margin-top: 1rem;
    font-size: .9rem;
}

.close {
    display: block;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.hamburger {
    display: none;  
    cursor: pointer;
}

.cart-text {
    color: black;
}


.upAdmin {
    display: block;
    width: .7rem;
    height: .4rem;
    cursor: pointer;
    margin-top: .4rem;
}

.downAdmin{
    display: none;  
    cursor: pointer;
}

.admin-content {
    margin-left: 1.2rem;
}

.profile-signout {
    margin-top: .8rem;
    font-size: .9rem;
}

.brand-modal {
    /* border: 1px red solid; */
    font-family: 'Inter', sans-serif;
    background-color: white;
    /* height: 320px; */
    width: 58%;
    /* margin-top: -16px; */
    position: absolute;
    top: 227.4px;
    z-index: 99;
    box-shadow: -1px .2px 4px rgb(148, 148, 148);
}

.brand-modal ul li:hover {
    font-weight: 500;
    width: auto;
}
