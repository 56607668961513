
.test-slider .slide {
    margin-top: 9rem;
    text-align: center;
    /* transition: all 0.5s ease; */ 
    padding: 0 2rem;
}


.test-slider .slide img {
    margin: auto;
    width: 4.1rem;
    height: 4rem;
    border-radius: 50%;
    margin-bottom: 1.5rem;
}

/* .current {
    opacity: 1;
    transform: translateX(0);
  } */


.test-slider .arrow {
    border: none;
    background-color: transparent;
    color: rgb(215, 213, 213);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    top: 11rem;
    left: 83%;
}

.test-slider .arrow:hover {
    background-color: white;
    color: gray;
}

.test-slider .next {
    right: -5rem;
    
}

.test-slider .prev {
    left: 5rem;
}

.test-slider .content > * {
    color: black;
    margin-bottom: 1rem;
  }
  
  .test-slider .current {
    opacity: 1;
    transform: translateX(0);
  }
  
 .test-slider .current .content {
    opacity: 1;
  }

.test-slider .text{
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
}

.test-slider .name{
    color: #c5c5c5d9;
    font-family: 'Inter', sans-serif;
}

.test-slider {
    color: #c5c5c5d9;
    font-family: 'Inter', sans-serif;
}

.test-slider .test-location {
    color: #c5c5c5d9;
    font-family: 'Inter', sans-serif;
}

.test-slider .entry-rating {
    margin: auto;
    width: 95px;
    margin-bottom: 1.5rem;
}

@media screen and (min-width: 600px) {
    .test-slider {
        width: 70%;
        margin: auto;
        position: relative;
    }

}

