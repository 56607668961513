.message-box-text {
    font-size: 0.9rem;
    width: 100%;
}

.edit-user-page .input {
    outline: 2.3px #27272747 solid;
    height: 2.5rem;
    border-radius: .3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}

.edit-user-page {
    font-family: 'Inter', sans-serif;
    color: #1a1a1a;
    font-size: .9rem;
}

.edit-user-page .edit-user-label {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 0.95rem;
    font-weight: 600;
}

.edit-user-page .edit-user-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    padding: 0.8rem 0;
   width: 170px;
}

.edit-user-page .edit-user-cancel-button {
    background-color: #e4e4e7;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    padding: 0.8rem 0;
    width: 170px;
    color: black;
    font-weight: 500;
}


@media screen and (min-width: 600px) {
    .edit-user-page .edit-user-button-group {
        width: 360px;
    }
}