footer {
    background-color: #1a1a1a14;
}

footer span {
    color: #1a1a1a7c;
}

footer .footer-form h1 {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

footer .footer-form p {
    color: #1a1a1a68;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

footer .link-contact ul {
    color: #1a1a1a68;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
}

footer .link-contact ul li {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 1rem;
}

footer .link-contact ul li:hover {
    font-weight: 500;
}

footer input::placeholder {
    color: #1a1a1a50;
    font-size: 0.85rem;
}

footer .payment-text {
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 1rem;
    line-height: 2.3rem;
}


/* large screen */
@media screen and (min-width: 600px) {
    footer .footer-form {
        width: 450px;
    }

    footer input {
        outline: 2.3px #27272747 solid;
        height: 2.5rem;
        border-radius: .3rem;
        padding-left: 1rem;
        font-size: 1rem;
        width: 280px;
    }

    footer .footer-form .button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        padding: 0.5rem 1rem;
        width: 150px;
        height: 2.7rem;
    }
    
}

/* small screen */
@media screen and (max-width: 600px) {
    footer .footer-form {
        width: 100%;
    }

    footer input {
        outline: 2.3px #27272747 solid;
        height: 2.5rem;
        border-radius: .3rem;
        padding-left: 1rem;
        font-size: 1rem;
        width: 100%;
    }

    footer .footer-form .button {
        background-color: #003466;
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        padding: 0.5rem 1rem;
        height: 2.7rem;
        width: 100%;
        margin-top: 1.7rem;
        
    }
    
}


