.store-comp {
    font-family: 'Inter', sans-serif;
    height: 100%;
    margin: 7rem 0 5rem;
    position: relative;
    /* margin-bottom: 15rem; */
  }

.store-comp .store-img {
    background-image: url("./web-store-1.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    height: 700px;
  }

  .store-comp .store-img {
    height: 700px;
    width: 100%;
  }

.store-comp .store-content {
    background-color: #00162b;
    text-align: center;
    border-radius: 1.3rem;
    padding: 3rem 0;
}

.store-comp .store-content h2 {
    color: #ffffff;
    font-size: 1.8rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    margin-bottom: 1.9rem;
}

.store-comp .store-content p {
    font-size: 1rem;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    margin-bottom: 1.9rem;
}

.store-comp .store-content button {
    background-color: #00162b;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #ffffff;
    width: 150px;
    font-weight: 500;
    cursor: pointer;
    padding: 0.8rem;
    border: 1px #ffffff solid;
    border-radius: 0.5rem;
}

@media screen and (min-width: 600px) {
    .store-comp .store-content {
        background-color: #00162b;
        text-align: center;
        border-radius: 1.3rem;
        padding: 3rem 0;
        margin-top: -150px;
        /* position: absolute;
        left: 20rem; */
    }

    .store-slider .store-slide {
        transform: translateX(0%);
        transition: all 0.5s ease-in-out;
        text-align: center;
        color: #1a1a1a;
        /* margin-top: .8rem; */
        /* font-family: 'Inter', sans-serif; */
    }

    .store-slider {
        width: 100%;
        position: relative;
        overflow: hidden;
        /* height: 54px; */
    }

    .store-slider .store-current {
        opacity: 1;
        transform: translateX(100%);
    }
}

