.product-page {
    font-family: 'Inter', sans-serif;
}

.currency {
    margin-top: 1rem;
    margin-bottom: .2rem;
    font-size: .9rem;
}

.currency span {
   font-size: .9rem;
   padding-bottom: .25rem;
}

small {
    color: #1a1a1a84;
}

.product-page .specs .size {
    margin-top: .5rem;
}

.product-page .specs .size p {
    color: #1a1a1a;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
}

.product-page .specs .size .product-size-text {
    color: #676767;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.7rem;
}

.product-page .specs .size .size-button {
    display: flex;
    flex-wrap: wrap;
}

.product-page .specs .size .buttons {
    display: none;
}

.buttons-active {
    display: block;
}

.countries {
    width: 170px;
}

.countries p {
    width: 70px;
    cursor: pointer;
}

.country-text {
    border-bottom: .12rem #1a1a1a solid;
    font-weight: 700;
}

.country-text-inactive {
    border-bottom: .12rem #e7e7e7 solid;
    font-weight: 700;
}

.product-page .specs .size .size-button button {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    padding: 0.3rem 0.5rem;
    border: 1px #1a1a1a73 solid;
    margin-right: 0.7rem;
    margin-bottom: 0.7rem;
    border-radius: .5rem;
    font-size: .9rem;
    color: #1a1a1aa1;
    width: 40px;
}


.product-page .specs .size .size-button .button-select {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    padding: 0.2rem 0.4rem;
    border: 1px #636363 solid;
    margin-right: 0.7rem;
    margin-bottom: 0.7rem;
    border-radius: .5rem;
    background-color: #003466;
    font-size: .9rem;
    color: #ffffff;
    width: 40px;
}

.product-buttons {
    margin-top: 1.4rem;
}

.product-buttons .buy-now {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #ffffff;
    cursor: pointer;
    padding: 1rem;
    border-radius: 0.5rem;
}

.product-buttons .add-cart {
    background-color: #f4f4f5;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #1a1a1a;
    cursor: pointer;
    padding: 0.8rem;
    border: 1px #003466 solid;
    border-radius: 0.5rem;
}




@media screen and (min-width: 600px) {
    .product-page {
        font-family: 'Inter', sans-serif;
        width: 940px;
        margin-top: 4rem;
    }

    .product-page .product-page-image{
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 400px;
        border-radius: .3rem;
    }
    
    .product-page .product-page-image-specs{
        display: block;
        width: 400px;
        height: 570px;
        padding: 1rem;
        border-radius: .4rem;
    }
    
    .product-page .thumbnails{
        margin: 1rem auto;
    }

    .product-page .specs-content{
        width: 500px;
    }
    
    .product-page .thumbnails .thumbnail{
        outline: 1px rgba(173, 173, 173, 0.709) solid;
        border-radius: .8rem;
    }

    .product-page .specs .price{
        font-size: 1.3rem;
        color: #1a1a1a;
        margin-top: .5rem;
        font-weight: 500;
    }
  
    .product-page .specs h1{
        font-size: 1.3rem;
        margin-bottom: .5rem;
    }
}

@media screen and (max-width: 600px) {
    .product-page {
        font-family: 'Inter', sans-serif;
        width: 90%;
        margin-top: 2rem;
    }

    .product-page .product-page-image{
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 400px;
        border-radius: .3rem;
    
    }
    
    .product-page .product-page-image-specs{
        display: block;
        /* border: 1px rgba(173, 173, 173, 0.709) solid; */
        width: 100%;
        height: 555px;
        padding: 1rem;
        border-radius: .4rem;
    }
    
    .product-page .thumbnails{
        margin: 1rem auto;
    }
    
    .product-page .thumbnails .thumbnail{
        outline: 1px rgba(173, 173, 173, 0.709) solid;
        border-radius: .8rem;
    }

    .product-page .specs .price{
        font-size: 1.0rem;
        color: #1a1a1a;
        margin-top: .5rem;
        font-weight: bold;
    }
  
    .product-page .specs h1{
        font-size: 1.1rem;
        margin-bottom: .5rem;
    }
}


.product-page .specs .brand{
    font-size: 0.9rem;
    color: #1a1a1a97;
    margin-bottom: .2rem;
}


.product-page .product-details {
    margin-top: 3.7rem;
    font-size: .9rem;
}


.product-page .product-details .description-group  {
    padding-top: 1rem;
}


.product-page .product-details .description-group .description-content p {
    color: #1a1a1aae;
    margin-bottom: 0.45rem;
}

.product-page .product-details .description-group .description-content span {
    color: #1a1a1a;
}

.product-page .product-details .description-group {
    border-bottom: 1px #1a1a1a4c solid;
    padding-bottom: 0.2rem;
}

.upDescription {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
}

.downDescription {
    display: none;  
    cursor: pointer;
}


.product-page .product-details .details-group .details-content p {
    color: #1a1a1aae;
    margin-bottom: 0.45rem;
    line-height: 1.6rem;
}

.product-page .product-details .details-group .details-content span {
    color: #1a1a1a;
}


.product-page .product-details .details-group {
    border-bottom: 1px #1a1a1a4c solid;
    padding-bottom: 0.2rem;
    padding-top: 1.2rem;
}

.upDetails {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
}

.downDetails {
    display: none;  
    cursor: pointer;
}

.product-page .product-details .review-group {
    border-bottom: 1px #1a1a1a4c solid;
    padding-bottom: 0.2rem;
    padding-top: 1.2rem;
}

.upReview {
    display: block;
    width: 0.8rem;
    height: 0.5rem;
    cursor: pointer;
}

.downReview {
    display: none;  
    cursor: pointer;
}

.product-page .product-details .review-group .review-content .review {
    margin-bottom: 1.05rem;
    width: 95%;
}

.product-page .product-details .review-group .review-content .review .date {
    color: #1a1a1aa5;
}

.product-page .product-details .review-group .review-content .review .comment {
    color: #1a1a1ad3;
    font-size: .9rem;
    width: 250px;
}

.product-page .product-details .review-group .review-content .review .name-date {
    text-align: right;
}

.product-page .write-review {
    margin-top: 1.7rem;
}

.product-page .write-review h2 {
    margin-bottom: 1.4rem;
    font-weight: bold;
}
.write-review .rating-group .label {
    font-size: 0.9rem;
}

.write-review .rating-group option {
    font-size: 0.9rem;
}

.write-review .comment-text {
    font-size: 0.9rem;
}


.write-review .drop-a-review {
    font-size: 0.9rem;
}

.write-review .comment-button {
    background-color: #003466;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #ffffff;
    cursor: pointer;
    padding: 1rem;
    border-radius: 0.5rem;
}

.product-availability {
    font-size: .75rem;
    font-style: italic;
    margin-top: .8rem;
    color: rgba(197, 197, 197, 0.916);
}

.select-country {
    font-weight: 500;
}






